import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "MicrosoftTeams-image2.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <div id="winners">
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          id="hero-section"
          style={{ marginTop: `70px`, }}
        >
        </BackgroundImage>
          <div className="hero-textbox pad">
            <div id="award-hero-textbox">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12" style={{
                    maxWidth: `880px`,
                    marginLeft: `auto`,
                    marginRight: `auto`,
                  }}>
                    <h1 className="mb-5 semibold serif">Winners</h1>
                    <p className="copy mb-5 text-left">The inaugural competition for the Dan Rather Medals for News and Guts generated outstanding work from student and professional journalists across the country. The entries covered a range of topics – from city, county and federal organizations to school administration to for-profit companies. What they shared in common was a demonstrated commitment to reporting the tough stories of 2023 that needed to be told.
</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }}
  />
)

const WinnersStyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default WinnersStyledBackgroundSection
