import React from "react"
import WinnersStyledBackgroundSection from "../components/winners-background-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Img from "gatsby-image"
import Link from "gatsby-link"
import WinnerCard from "../components/WinnerCard";
import { graphql, useStaticQuery } from "gatsby";
import winnersData from "../data/winners.json";

const Awards = () => {
  const data = useStaticQuery(graphql`
  query {
    allImageSharp {
      nodes {
        fluid {
          ...GatsbyImageSharpFluid
          originalName
        }
      }
    }
  }
`);

  // Create a mapping of image original names to fluid data
  const imageMap = data.allImageSharp.nodes.reduce((acc, node) => {
    acc[node.fluid.originalName] = node.fluid;
    return acc;
  }, {});

  return (
    <Layout>
      <Seo title="Winners" />
      <WinnersStyledBackgroundSection />
      {winnersData.map((winner) => (
        <WinnerCard
          key={winner.name}
          imageData={imageMap[winner.imagePath.split('/').pop()]}
          imageAlt={winner.imageAlt}
          name={winner.name}
          awardTitle={winner.awardTitle}
          description={winner.description}
        />
      ))}
      
    </Layout>
  )
}

export default Awards
