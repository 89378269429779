import React from "react";
import Img from "gatsby-image";

const WinnerCard = ({ imageData, name, awardTitle, description, imageAlt }) => {
  return (
    <div className="image-row winners">
      <div className="image-wrapper">
        <Img fluid={imageData} alt={imageAlt} style={{ width: `100%` }} />
      </div>
      <div className="image-row-textbox">
        <div className="name">{name}</div>
        <div className="title mb-4">{awardTitle}</div>
        <p className="copy" dangerouslySetInnerHTML={{ __html: description }} />
        {/* Note: Using dangerouslySetInnerHTML to render HTML content from props. 
                 Make sure the content is from a trusted source to prevent XSS attacks. */}
      </div>
    </div>
  );
};

export default WinnerCard;
